<template>
  <div>
    <filters-form @submit="filters = $event"/>

    <app-card>
      <template #body>
        <app-table request-url="/api/tickets/table" :fields="fields" :filters="filters" :row-class="rowClass">
          <template #cell(title)="data">
            <router-link :to="{name: 'showTicket', params: {id: data.item.id}}">
              {{ data.value }}
            </router-link>
          </template>
          <template #cell(complex)="data">{{ data.item.location ? data.item.location.block.complex.name : '' }}</template>
          <template #cell(block)="data">{{ data.item.location ? data.item.location.block.name : '' }}</template>
          <template #cell(location_type)="data">{{ data.value.name }}</template>
          <template #cell(location)="data">{{ data.value.name }}</template>
          <template #cell(status)="data">
            <span class="label label-pill label-inline" :class="`label-light-${getTicketStatusClass(data.value.name)}`">
              {{ $t(`status.${data.value.name}`) }}
            </span>
          </template>
          <template #cell(responsible)="data">
            <router-link :to="{name: 'updateUser', params: {id: data.value.id}}">
              {{ data.value.label }}
            </router-link>
          </template>
        </app-table>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <router-link :to="{name: 'createTicket'}" class="btn btn-transparent-white">
        {{ $t('btn.create_ticket') }}
      </router-link>
    </portal>
  </div>
</template>

<script>
import {getTicketStatusClass} from '@/helpers/statuses-helper';
import AppCard from '@/components/AppCard';
import FiltersForm from './components/FiltersForm';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    FiltersForm,
    AppTable
  },
  data() {
    return {
      filters: null,
      fields: [
        {key: 'title', label: this.$t('label.title')},
        {key: 'description', label: this.$t('label.description')},
        {key: 'complex', label: this.$t('label.complex')},
        {key: 'block', label: this.$t('label.block')},
        {key: 'location_type', label: this.$t('label.location_type')},
        {key: 'location', label: this.$t('label.location')},
        {key: 'status', label: this.$t('label.status'), sortable: true},
        {key: 'responsible', label: this.$t('label.responsible')},
        {key: 'created_at', label: this.$t('label.created_at')},
        {key: 'deadline', label: this.$t('label.deadline')}
      ]
    }
  },
  methods: {
    getTicketStatusClass,
    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (item.deadline_warning && item.status.name !== 'completed') return 'bg-warning-o-30';
    }
  }
}
</script>