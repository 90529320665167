<template>
  <app-filters :filters="form" @submit="$emit('submit', $event)">
    <template #body>
      <b-row>
        <b-col md="3">
          <app-input
              v-model="form.title"
              :label="$t('label.title')"
              :placeholder="$t('placeholder.enter_title')"
          />
        </b-col>
        <b-col md="3">
          <app-input
              v-model="form.description"
              :label="$t('label.description')"
              :placeholder="$t('placeholder.enter_description')"
          />
        </b-col>
        <b-col md="3">
          <app-multiselect
              :label="$t('label.status')"
              :placeholder="$t('placeholder.select_statuses')"
              :value="statusesList.filter(el => form.status.includes(el.id))"
              :options="statusesList"
              multiple
              @select="form.status.push($event.id)"
              @remove="form.status = form.status.filter(el => el !== $event.id)"
          />
        </b-col>
        <b-col md="3">
          <app-multiselect
              :label="$t('label.complex')"
              :placeholder="$t('placeholder.select_complex')"
              :value="complexesList.find(el => el.id === form.complex)"
              :options="complexesList"
              @select="form.complex = $event.id"
              @remove="form.complex = null"
          />
        </b-col>
        <b-col md="3">
          <app-multiselect
              :label="$t('label.block')"
              :placeholder="$t('placeholder.select_block')"
              :value="blocksList.find(el => el.id === form.block)"
              :options="blocksList"
              :disabled="!computedSelectedComplex"
              @select="form.block = $event.id"
              @remove="form.block = null"
          />
        </b-col>
        <b-col md="3">
          <app-input
              v-model="form.location"
              :label="$t('label.location')"
              :placeholder="$t('placeholder.enter_location_name')"
          />
        </b-col>
        <b-col md="3">
          <app-multiselect
              :label="$t('label.responsible')"
              :placeholder="$t('placeholder.select_responsibles')"
              :value="usersList.filter(el => form.responsible.includes(el.id))"
              :options="usersList"
              multiple
              @select="form.responsible.push($event.id)"
              @remove="form.responsible = form.responsible.filter(el => el !== $event.id)"
          />
        </b-col>
        <b-col md="3">
          <app-multiselect
              :label="$t('label.created_by')"
              :placeholder="$t('placeholder.select_creators')"
              :value="usersList.filter(el => form.creator.includes(el.id))"
              :options="usersList"
              multiple
              @select="form.creator.push($event.id)"
              @remove="form.creator = form.creator.filter(el => el !== $event.id)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <app-datepicker
              :label="$t('label.created_at')"
              :placeholder="$t('label.from')"
              :value="form.created_at_from"
              @change="form.created_at_from = $event"
          />
        </b-col>
        <b-col md="3">
          <app-datepicker
              :label="$t('label.created_at')"
              :placeholder="$t('label.to')"
              :value="form.created_at_to"
              @change="form.created_at_to = $event"
          />
        </b-col>
        <b-col md="3">
          <app-datepicker
              :label="$t('label.updated_at')"
              :placeholder="$t('label.from')"
              :value="form.updated_at_from"
              @change="form.updated_at_from = $event"
          />
        </b-col>
        <b-col md="3">
          <app-datepicker
              :label="$t('label.updated_at')"
              :placeholder="$t('label.to')"
              :value="form.updated_at_to"
              @change="form.updated_at_to = $event"
          />
        </b-col>
      </b-row>
    </template>
  </app-filters>
</template>

<script>
import {mapGetters} from 'vuex';
import AppFilters from '@/components/filters/AppFilters';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppDatepicker,
    AppFilters,
    AppInput,
    AppMultiselect,
  },
  data: () => ({
    form: {
      block: null,
      complex: null,
      created_at_from: null,
      created_at_to: null,
      description: null,
      location: null,
      title: null,
      creator: [],
      responsible: [],
      status: [],
      updated_at_from: null,
      updated_at_to: null
    }
  }),
  beforeMount() {
    this.$store.dispatch('complexesStore/GET_LIST');
    this.$store.dispatch('ticketsStore/GET_STATUSES');
    this.$store.dispatch('usersStore/GET_USERS');

    if (Object.keys(this.$route.query).length) {
      this.form.complex = parseInt(this.$route.query?.complex_id);
      if (this.$route.query?.status_id == 0) {
        const today = new Date();
        const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        this.form.updated_at_from = todayStr;
        this.form.updated_at_to = todayStr;
      } else {
        this.form.status = [this.$route.query?.status_id];
      }

      const filters = Object.fromEntries(Object.entries(this.form).filter(([_, v]) => {
        return Array.isArray(v) ? v.length : v
      }));

      this.$emit('submit', filters);
    }
  },
  computed: {
    ...mapGetters({
      blocksList: 'blocksStore/BLOCKS',
      complexesList: 'complexesStore/COMPLEXES',
      statusesList: 'ticketsStore/STATUSES',
      usersList: 'usersStore/USERS',
    }),
    computedSelectedComplex() {
      return this.form.complex;
    }
  },
  watch: {
    computedSelectedComplex(id) {
      this.form.block = null;
      if (id) {
        this.$store.dispatch('blocksStore/GET_BLOCKS', {complex_id: id})
      }
    }
  }
}
</script>